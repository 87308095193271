import React, { useState, useEffect } from 'react'
import { navigate } from 'gatsby'
import { usePrismic } from '../context/PrismicContext'
// Components
import { Seo, PickYourPerksAmbEnrModal } from '../components'
// Context
import { useAuthContext } from '../context/AuthContext'

const Enrollment = () => {
  const { userType, isAuthLoading, isAuthenticated } = useAuthContext()
  const [isPerksModalOpen, setIsPerksModalOpen] = useState(true)
  const {
    prismicData: { prismicGeneral },
  } = usePrismic()

  useEffect(() => {
    if (isAuthLoading) return
    if (userType === 'AMBASSADOR') {
      alert(prismicGeneral.msg_it_looks_like_you_are_already_an_ambassador)
      localStorage.removeItem('enrollmentForm')
      navigate('/')
    }
    if (!isAuthenticated && !isAuthLoading) navigate('/login')
  }, [isAuthLoading, isAuthenticated, userType])

  return (
    <>
      <Seo title="enrollment form" />
      {isPerksModalOpen && (
        <PickYourPerksAmbEnrModal
          open={isPerksModalOpen}
          onClose={() => {
            setIsPerksModalOpen(false)
            navigate('/enrollment-form')
          }}
        />
      )}
    </>
  )
}

export default Enrollment
